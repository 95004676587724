const brand = {
    // black & white
    white: '#FFFFFF',
    black: '#000000',
    // primary
    primary: '#47FCE9',
    // secondary
    secondary: '#9A50E4',
    grey: '#6A6A6A',
    blue: '#0029FF',
    orange: '#DC600B',
    pink: '#F8478B',
    purple: '#3B254C',
    yellow: '#F8FFD2',
    error: '#FF0F00',
    lightGray: '#584865',
};
export const colors = {
    text: {
        primary: brand.primary,
        secondary: brand.secondary,
        normal: brand.black,
        link: '#F367A4',
        darkPink: '#940505',
        lightPink: '#FF719C',
        pink: '#F8478B',
        singleBest: '#FD80A6',
        blue: '#1EA2ED',
        error: '#F8474A',
    },
    bg: {
        cardSolid: '#E69055',
        main: '#CDFFF9',
        footer: '#C66944',
        infoCard: '#FFD9BF',
        singleBest: '#FFBFD2',
        pink: '#FFBFD7',
        gray: '#BBBBBB',
        purple: '#6F0079',
        lightYellow: '#FBFFE2',
    },
    white: brand.white,
    black: brand.black,
    brand,
};
