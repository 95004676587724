import { Error, Tick, Wallet } from '@ballz-app/icons';
import {
  Box,
  Button,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  VStack,
} from '@chakra-ui/react';
import { StrokeText } from '@ui/components/StrokeText';
import type { ErrorResponse } from '@ui/hooks/query/type';
import { BindError } from '@ui/hooks/query/type';
import { useBindWallet } from '@ui/hooks/query/useBindWallet';
import { useBallzToast } from '@ui/hooks/useBallzToast';
import { ethers } from 'ethers';
import { useCallback, useEffect, useMemo, useState } from 'react';

export const AddressInput = ({ onClose }: { onClose: () => void }) => {
  const [address, setAddress] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const { mutateAsync: submit, isLoading } = useBindWallet();
  const toast = useBallzToast();

  const isValid = useMemo(() => {
    const trimAddress = address.trim();
    return ethers.isAddress(trimAddress);
  }, [address]);

  useEffect(() => {
    if (address && !isValid) {
      setErrorMsg(
        "Check your wallet format and try again. If it still doesn't fit, reach out to our team on TG!",
      );
    } else {
      setErrorMsg('');
    }
  }, [address, isValid]);

  const onSubmit = useCallback(async () => {
    try {
      await submit({ address: address.trim() });
      setErrorMsg('');
      onClose();
      toast({
        description: 'Your wallet is successfully bound!',
        status: 'success',
        icon: <Wallet />,
      });
    } catch (e) {
      let msg =
        "Something went wrong. Please try again! If it still doesn't work, reach out to our team on TG!";
      if (
        e &&
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        (e as ErrorResponse).error_code ===
          BindError.WALLET_ADDRESS_ALREADY_USED
      ) {
        msg =
          'This wallet address is already bound to another Telegram account. Please check again!';
      }
      setErrorMsg(msg);
    }
  }, [address, onClose, submit, toast]);

  return (
    <VStack width="full" gap={6}>
      <VStack width="full" gap={3}>
        <Text color="brand.purple" alignSelf="flex-start">
          Wallet Address
        </Text>
        <InputGroup borderColor="brand.black" alignItems="center">
          <Input
            size="lg"
            fontSize={16}
            placeholder={'0x.......'}
            p={3}
            pr={12}
            borderRadius={4}
            value={address}
            onChange={(e) => {
              setAddress(e.target.value);
            }}
            borderColor={'brand.primary'}
            _placeholder={{
              color: '#AAA8A8',
            }}
            _focusVisible={{
              borderColor: errorMsg ? 'text.error' : 'brand.primary',
              bgColor: '#B7FEF6',
            }}
          />
          <InputRightElement mt={1} mr={2}>
            {address && (
              <Button
                variant="link"
                color="text.error"
                fontSize={12}
                fontWeight={400}
                onClick={() => {
                  setAddress('');
                }}
              >
                Clear
              </Button>
            )}
          </InputRightElement>
        </InputGroup>
        {errorMsg && (
          <HStack w="full">
            <Box>
              <Error />
            </Box>
            <Text color="text.error" fontWeight={400} fontSize={12}>
              {errorMsg}
            </Text>
          </HStack>
        )}
      </VStack>

      <VStack width="full" gap={3} alignItems="flex-start">
        <StrokeText
          color="brand.yellow"
          fontSize={15}
          lineHeight="14px"
          strokeWidth="4px"
          strokeColor="#3B254C"
        >
          Why do I need to bind my wallet?
        </StrokeText>
        <HStack>
          <Tick />
          <Text fontWeight={400} fontSize={12}>
            Buy more Ballz with $zkCRO
          </Text>
        </HStack>
        <HStack>
          <Tick />
          <Text fontWeight={400} fontSize={12}>
            Enjoy upcoming airdrops
          </Text>
        </HStack>
      </VStack>

      <Button
        border="3px solid #3B254C"
        borderRadius={16}
        boxShadow="4px 4px 0px 0px #FF7BAE"
        bg="brand.pink"
        position="relative"
        onClick={onSubmit}
        isDisabled={isLoading || !isValid}
        pt={4}
        pb={2.5}
        w="full"
        size="lg"
        _hover={{
          boxShadow: 'unset',
        }}
        _active={{
          boxShadow: 'unset',
        }}
      >
        <HStack align="baseline">
          <StrokeText
            color="brand.primary"
            fontSize={21}
            lineHeight="21px"
            strokeWidth="6px"
            strokeColor="#3B254C"
          >
            CONFIRM AND BIND
          </StrokeText>
        </HStack>
      </Button>
    </VStack>
  );
};
