export const versionCompare = (v1: string, v2: string) => {
  if (typeof v1 !== 'string') v1 = '';
  if (typeof v2 !== 'string') v2 = '';
  const v1Arr = v1.replace(/^\s+|\s+$/g, '').split('.');
  const v2Arr = v2.replace(/^\s+|\s+$/g, '').split('.');
  const maxLen = Math.max(v1Arr.length, v2Arr.length);
  let i, p1, p2;
  for (i = 0; i < maxLen; i++) {
    p1 = parseInt(v1Arr[i]) || 0;
    p2 = parseInt(v2Arr[i]) || 0;
    if (p1 == p2) continue;
    if (p1 > p2) return 1;
    return -1;
  }
  return 0;
};

export const versionTgAtLeast = (ver: string, webAppVersion?: string) => {
  if (typeof webAppVersion === 'undefined') {
    return false;
  }

  return versionCompare(webAppVersion, ver) >= 0;
};
