import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useId } from 'react';
const SvgCro = function (props) {
    const __useId = useId();
    return (_jsxs("svg", { width: "1em", height: "1em", viewBox: "0 0 29 29", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...props, children: [_jsx("path", { d: "m14.075 0 6.123 3.47 6.067 3.568.056 7.037-.056 7.038-6.067 3.567-6.123 3.47-6.122-3.47-6.067-3.567-.056-7.038.056-7.037L7.953 3.47 14.075 0Z", fill: "#fff" }), _jsxs("g", { clipPath: `url(#${'cro_svg__a'}__${__useId})`, children: [_jsx("path", { d: "M14.08 1.71 3.322 7.896V20.26l10.756 6.18 10.748-6.18V7.895L14.08 1.711Zm7.563 16.718-7.564 4.348-7.567-4.348V9.723l7.567-4.348 7.564 4.348v8.705Z", fill: "#002D74" }), _jsx("path", { style: {
                            mixBlendMode: 'multiply',
                        }, d: "m14.08 26.44 10.747-6.18V7.895L14.08 1.711v3.668l7.563 4.349v8.704l-7.563 4.344v3.665Z", fill: `url(#${'cro_svg__b'}__${__useId})` }), _jsx("path", { style: {
                            mixBlendMode: 'multiply',
                        }, d: "M14.073 1.71 3.325 7.892v12.365l10.748 6.184v-3.668l-7.564-4.348V9.719l7.564-4.344V1.711Z", fill: `url(#${'cro_svg__c'}__${__useId})` }), _jsx("path", { d: "m19.095 16.965-5.02 2.884-5.024-2.884V11.19l5.024-2.889 5.02 2.89-2.09 1.201-2.93-1.687-2.93 1.688v3.365l2.93 1.687 2.93-1.687 2.09 1.207Z", fill: "#002D74" })] }), _jsxs("defs", { children: [_jsxs("linearGradient", { id: `${'cro_svg__b'}__${__useId}`, x1: 19.453, y1: 26.441, x2: 19.453, y2: 14.076, gradientUnits: "userSpaceOnUse", children: [_jsx("stop", { stopColor: "#002D74" }), _jsx("stop", { offset: 1, stopColor: "#002D74", stopOpacity: 0 })] }), _jsxs("linearGradient", { id: `${'cro_svg__c'}__${__useId}`, x1: 8.699, y1: 1.711, x2: 8.699, y2: 14.076, gradientUnits: "userSpaceOnUse", children: [_jsx("stop", { stopColor: "#002D74" }), _jsx("stop", { offset: 1, stopColor: "#002D74", stopOpacity: 0 })] }), _jsx("clipPath", { id: `${'cro_svg__a'}__${__useId}`, children: _jsx("path", { fill: "#fff", transform: "translate(1.173 1.173)", d: "M0 0h25.805v25.805H0z" }) })] })] }));
};
export default SvgCro;
