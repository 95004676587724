export enum Tier {
  Steel = 'Steel',
  Bronze = 'Bronze',
  Silver = 'Silver',
  Gold = 'Gold',
  Diamond = 'Diamond',
}

export interface ErrorResponse {
  error_code: AuthError | GameResultError | TaskError | BindError;
  message: string;
}

export enum AuthError {
  TG_AUTH_VERIFY_FAIL = 'TG_AUTH_VERIFY_FAIL',
  TG_AUTH_TOKEN_EXPIRED = 'TG_AUTH_TOKEN_EXPIRED',
  TG_AUTH_TOKEN_EMPTY = 'TG_AUTH_TOKEN_EMPTY',
}

export enum GameResultError {
  GAME_BALLS_NOT_ENOUGH = 'GAME_BALLS_NOT_ENOUGH',
  COST_BALLS_ILLEGAL = 'COST_BALLS_ILLEGAL',
  GAME_BALLS_MAX_DROP_OVER_TIER_LIMIT = 'GAME_BALLS_MAX_DROP_OVER_TIER_LIMIT',
  USER_ACCOUNT_NOT_FIND = 'USER_ACCOUNT_NOT_FIND',
}

export enum TaskError {
  TASK_NOT_FIND = 'TASK_NOT_FIND',
  TASK_OPERATE_NOT_SUPPORT = 'TASK_OPERATE_NOT_SUPPORT',
  TASK_INCOMPLETE = 'TASK_INCOMPLETE',
  TASK_ALREADY_CLAIMED = 'TASK_ALREADY_CLAIMED',
  TASK_DAILY_CHECKIN_BREAK = 'TASK_DAILY_CHECKIN_BREAK',
  TASK_ALREADY_COMPLETE = 'TASK_ALREADY_COMPLETE',
}

export enum BindError {
  WALLET_ADDRESS_ALREADY_USED = 'WALLET_ADDRESS_ALREADY_USED',
}
