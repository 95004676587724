import { useMutation, useQueryClient } from '@tanstack/react-query';
import { getApiUrl } from '@ui/utils';
import { useTgInfoStore } from '@ui/views/Index/TgInfoView/useTgInfoStore';

export const useBindWallet = () => {
  const queryClient = useQueryClient();
  const { tgId, initData } = useTgInfoStore((s) => {
    return {
      tgId: s.user.id,
      initData: s.initData,
    };
  });

  return useMutation({
    mutationKey: ['bind_wallet', tgId, initData],
    mutationFn: async ({ address }: { address: string }) => {
      const res = await fetch(getApiUrl(`/user/${tgId}/bind-wallet`), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'tg-init-data': initData ?? '',
        },
        body: JSON.stringify({
          address,
        }),
      });

      if (!res.ok) {
        const error = await res.json();
        return Promise.reject(error);
      }

      const jsonRes = await res.json();
      return jsonRes;
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(['userRanking', tgId, initData]);
      return data;
    },
  });
};
