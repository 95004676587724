import { Close, Steel } from '@ballz-app/icons';
import type { ToastId, UseToastOptions } from '@chakra-ui/react';
import { Divider, Flex, Icon, Text, useToast } from '@chakra-ui/react';
import { useCallback, useMemo } from 'react';

interface ToastTemplateProps {
  id?: ToastId;
  onClose?: () => void;
  tostOptions: UseToastOptions;
}
const emptyFunction = () => void 0;

const getColors = (status: UseToastOptions['status']) => {
  switch (status) {
    case 'error':
      return {
        borderColor: 'brand.error',
        bg: 'bg.pink',
      };
    default:
      return {
        borderColor: 'brand.primary',
        bg: 'bg.main',
      };
  }
};
const Render = ({
  onClose = emptyFunction,
  tostOptions,
}: ToastTemplateProps) => {
  const { status } = tostOptions;
  const colors = useMemo(() => {
    return getColors(status);
  }, [status]);
  return (
    <Flex
      maxWidth="350px"
      p={4}
      borderRadius={20}
      borderWidth={2}
      borderColor={colors.borderColor}
      bg={colors.bg}
      alignItems="center"
      gap={3}
      mb="92px"
    >
      {tostOptions.icon ?? <Steel fontSize="24px" />}
      <Text fontSize={16} lineHeight="14px" color="brand.purple">
        {tostOptions.description}
      </Text>
      <Divider
        orientation="vertical"
        borderColor={colors.borderColor}
        height="36px"
      />
      {tostOptions.isClosable !== false && (
        <Icon
          onClick={onClose}
          cursor="pointer"
          as={Close}
          fontSize={12}
          color="brand.purple"
        />
      )}
    </Flex>
  );
};

const defaultOptions: Partial<UseToastOptions> = {
  position: 'bottom',
  isClosable: true,
  duration: 3000,
};

export const useBallzToast = () => {
  const chakraToast = useToast();

  const toast = useCallback(
    (props: UseToastOptions) => {
      const containerStyle: UseToastOptions['containerStyle'] = {
        ...props.containerStyle,
        /**
         * Reset containerStyle
         */
        minWidth: 'unset',
        marginLeft: '0px',
        marginRight: '0px',
        marginBottom: '0px',
        maxWidth: '100vw',
        wordBreak: 'break-word',
        zIndex: 200000,
      };
      chakraToast.closeAll();
      return chakraToast({
        ...defaultOptions,
        containerStyle,
        render: (renderProps) => {
          return <Render tostOptions={props} {...renderProps} />;
        },
        ...props,
      });
    },
    [chakraToast],
  );

  return toast;
};
